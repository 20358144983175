:root {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  --white: #fff;
  --dark-blue: #021c2c;
  --blue: #11468b;
  --light-blue: #417f97;
  --cyan: #00d7f2;
  --light-grey: #ccc;
}

body {
  background: var(--white);
  color: var(--dark-blue);
  overflow-wrap: break-word;
  word-break: break-word;
  font-family: Georgia, Times New Roman, Times, serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.1833px;
}

h1, h2, h3, h4 {
  outline: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

h1 {
  margin-top: 56px;
  margin-bottom: 8px;
  font-size: 34px;
}

h2 {
  margin-top: 30px;
  margin-bottom: 6px;
  font-size: 26px;
}

p {
  margin-top: 0;
  margin-bottom: 29px;
}

blockquote {
  color: var(--light-blue);
  border-left: solid 2px var(--light-blue);
  margin: 0 0 29px;
  padding: 10px 40px;
  font-weight: bold;
}

blockquote p {
  margin-bottom: 0;
}

p img {
  max-width: 100%;
  margin-top: 20px;
}

.content a, .content a:visited, .footer a, .footer a:visited {
  color: var(--blue);
  text-decoration: underline;
  transition: color .2s ease-in-out;
}

.content a:hover, .footer a:hover {
  color: var(--light-blue);
}

.content a.anchorjs-link {
  color: var(--light-grey);
  text-decoration: none;
}

.content a.anchorjs-link:hover {
  color: var(--cyan);
}

.wrapper {
  max-width: 740px;
  grid-template-columns: auto 10%;
  grid-template-areas: "content tocw"
                       "footer footer";
  margin: auto;
  padding: 20px;
  display: grid;
}

.toc-wrapper {
  color: #73726f;
  grid-area: tocw;
  padding-left: 40px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.content {
  grid-area: content;
}

.footer {
  text-align: center;
  border-top: 1px solid var(--light-grey);
  grid-area: footer;
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  body {
    letter-spacing: -.072px;
    font-size: 18px;
    line-height: 1.22;
  }

  h1 {
    letter-spacing: -.45px;
    margin-top: 28px;
    margin-bottom: 8px;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  h2 {
    letter-spacing: -.288px;
    margin-top: 22px;
    margin-bottom: 6px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  p {
    margin-bottom: 21px;
    line-height: 1.58;
  }

  blockquote {
    padding-left: 20px;
  }

  .wrapper {
    grid-template-columns: 100%;
    grid-template-areas: "content"
                         "footer";
  }
}

.toc {
  position: fixed;
  top: 76px;
}

.toc a {
  text-decoration: none;
}

.toc a.node-name--H1 {
  font-size: 16px;
}

.toc a.is-active-link {
  color: var(--dark-blue);
}

.toc a.is-active-link:before {
  background-color: var(--cyan);
}

/*# sourceMappingURL=index.70a82b42.css.map */
